<template>
    <el-popover
            :placement="place"
            :width="width"
            v-model="visible">
        <p><span style="color:red">{{title}}</span></p>
        <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="visible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="handleConfirm">确定</el-button>
        </div>
        <template slot="reference">
            <slot></slot>
        </template>
    </el-popover>
</template>

<script>
    export default {
        name: "popup-confirm",
        data() {
            return {
                visible: false,
            }
        },
        props:{
            title:{type:String,default(){
                return '请确认操作';
                }},
            place:{type:String,default(){
                return 'top';
                }},
            width:{type:Number,default(){
                return 160;
                }}
        },
        methods:{
            handleConfirm(){
                this.visible = false;
                this.$emit('confirm');
            }
        }
    }
</script>

<style scoped>

</style>
