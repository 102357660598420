<template>
    <el-row :gutter="20" class="import-syllabus">
        <el-col :md="24" :sm="24" class="mb-3">
            <el-card shadow="never" :body-style="{backgroundColor:'#fbfbfe'}">
                <div class="content-wrapper">
                    <el-card class="action-card"
                             :body-style="{ padding: '0px',display:'flex',height:'100%',flexDirection:'column' }">
                        <div>
                            <img src="../assets/images/syllabus-import.png" width="100%"/>
                        </div>
                        <div class="text-left card-body">
                            <div class="">导入从教务处生成的课程表，请用 <a href="https://wps.com" target="_blank">WPS</a> 转成<code>csv</code>文件。
                                导入需要时间1-2分钟，请耐心等待。切记不可重复导入!
                            </div>
                            <div class="mt-3"><div style="font-size: 14px;font-weight:400;color: #000">下载课程表模板:</div>
                                <el-link type="primary" class="ml-2" href="/files/课程表模板.csv" target="_blank"><i class="el-icon-document"></i> csv</el-link>
                                <el-link type="primary" class="ml-3" href="/files/课程表模板.xlsx" target="_blank"><i class="el-icon-document"></i> xlsx</el-link>
                            </div>

                            <div class="mt-2" v-if="user.role_level == 4">
                                <el-button :icon="empty_confirm?'el-icon-caret-bottom':'el-icon-caret-right'" class="p-0"
                                           type="text" @click="empty_confirm=!empty_confirm"></el-button>
                                <el-collapse-transition>
                                    <div v-if="empty_confirm">
                                        <popup-confirm title="请谨慎! 确定要清空?" @confirm="handleEmpty"
                                                       confirmButtonType="text">
                                            <el-button type="text" class="text-danger" icon="el-icon-delete">清空课程表
                                            </el-button>
                                        </popup-confirm>
                                    </div>
                                </el-collapse-transition>
                            </div>
                            <div class="el-link d-block mt-1">
                                <router-link to="import-schedule"><i class="el-icon-link"></i> 生成时间表</router-link>
                            </div>
                            <div class="el-link d-block mt-1">
                                <router-link to="import-course"><i class="el-icon-link"></i> 生成课程会议号</router-link>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="d-flex justify-content-between">
                                <one-file-upload :upload-url="modelImportUrl" silent @progress="percentage = $event"
                                                 :extensions="['csv']" :extra="{channel:channel}"
                                                 @start="handleUploadStart" @success="handleUploadSuccess">
                                    <el-button plain type="primary"
                                               icon="el-icon-upload2">导入
                                    </el-button>
                                </one-file-upload>
                                <el-button plain type="danger" @click="handleStop"
                                           icon="el-icon-close">停止
                                </el-button>
                            </div>
                        </div>
                    </el-card>

                    <el-card :body-style="{ padding: '0px' }" class="process-card">
                        <div class="header">
                            <b>导入进程</b>
                        </div>
                        <div class="card-body position-relative" style="min-height: 370px;">
                            <div style="display: flex" class="">
                                <div style="flex:0 0 8em">
                                    上传进度条:
                                </div>
                                <div style="flex: 1 1 200px">
                                    <el-progress :text-inside="true" :stroke-width="25"
                                                 :percentage="percentage"></el-progress>
                                </div>
                            </div>
                            <pusher-information-box :events="events" size="small" progress></pusher-information-box>
                            <small class="text-muted status-bar">
                                <span v-if="status.busy == 1"><i class="el-icon-loading"></i> 处理中...</span>
                                <span v-else>空闲中...</span>
                                <span v-if="status.result" v-html="status.result"></span>
                            </small>
                        </div>
                    </el-card>
                </div>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import PopupConfirm from "../components/popup-confirm";
    import OneFileUpload from "../components/common-file-upload";
    import ImportMixin from '../common/mixins/import-mixins'
    import PusherInformationBox from "../components/PusherInformationBox";
    import Echo from "../common/mixins/echo-mixin"

    export default {
        mixins: [ImportMixin, Echo],
        name: "import-syllabus",
        components: {PusherInformationBox, PopupConfirm, OneFileUpload},
        data() {
            return {
                model: 'Admin.Syllabus',
                percentage:0
            }
        },
    }
</script>

<style lang="scss">
</style>
