import {mapState} from "vuex";
import FrontSingleFormEditor from "@/components/front-single-form-editor";
import Echo from "@/common/mixins/echo-mixin";
import XLSX from "xlsx";
import FileSaver from "file-saver"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import FakeFormItem from "@/components/FakeFormItem";
import VueSliceUploader from "@/components/slice-uploader"
import SemesterMixin from "@/views/mixins/semester.mixin"

export default {
    name: "editor",
    components: {FakeFormItem, FrontSingleFormEditor, VueQrcode, VueSliceUploader},
    mixins: [Echo, SemesterMixin],
    data() {
        return {
            single: {},
            fields: {},
            plans: [],
            schedule: [],
            edit_mode: true,
            dual: true,
            tab: 0,
            old_plan: {},
            show_table: true,
            table: {headers: [], data: [], title: ''},
            meetings: [],
            uuid: null,
            recordings: [],
            dialog_recording_add: false,
            record: {},
            recording_upload_url: null,
            cloud_records: [],
            cloud_record: {},
            cloud_record_dialog: false,
            pause_dates: [],
            today: new Date().Format("yyyy-MM-dd"),
            semester_index: 0,
            semester_options: {},
        }
    },
    created() {
        this.model = 'Admin.Plan';
        this.recording_upload_url = this.$apiUrl('Admin.Course.RecordingChunk');
    },
    watch: {
        $route() {
            let id = this.$route.params.id;
            this.loadLesson(id);
        },
    },
    computed: {
        ...mapState(['user', 'process', 'preset']),
        meetingNotCreated() {
            return !this.single || !this.single.id;
        },
        meetingClosedOrNotExists() {
            return !this.single || !this.single.id || this.single.is_closed + '' === "1";
        },
        filter_plans() {
            if (this.semester_index === 0) return this.plans;
            const selected = this.semester_options[this.semester_index];
            return this.plans.filter(i => {
                return (i.date_from == "") || (i.date_from <= selected.end_date && i.date_to >= selected.start_date);
            })
        },
        filter_schedule() {
            if (this.semester_index === 0) return this.schedule;
            const selected = this.semester_options[this.semester_index];
            return this.schedule.filter(i => {
                return (i.date <= selected.end_date && i.date >= selected.start_date);
            })
        },
        filter_cloud_records() {
            if (this.semester_index === 0) return this.cloud_records;
            const selected = this.semester_options[this.semester_index];
            return this.cloud_records.filter(i => {
                return (i.record_time.substr(0, 10) <= selected.end_date && i.record_time.substr(0, 10) >= selected.start_date);
            })
        },
        filter_meetings() {
            if (this.semester_index === 0) return this.meetings;
            const selected = this.semester_options[this.semester_index];
            return this.meetings.filter(i => {
                return (i.label.substr(0, 10) <= selected.end_date && i.label.substr(0, 10) >= selected.start_date);
            })
        },
        filter_recordings() {
            if (this.semester_index === 0) return this.recordings;
            const selected = this.semester_options[this.semester_index];
            return this.recordings.filter(i => {
                return (i.recording_date.substr(0, 10) <= selected.end_date && i.recording_date.substr(0, 10) >= selected.start_date);
            })
        },
        plan_in_edit() {
            let edit = false;
            this.plans.map(i => {
                if (i.edit) {
                    edit = true;
                }
            })
            return edit;
        },
        lessonRules() {
            let rules = {};
            for (let x in this.fields) {
                const field = this.fields[x];
                if (field.validation) {
                    rules[x] = [];
                    field.validation.forEach(item => {
                        const trigger = ['text', 'textarea', 'number'].includes(item.type) ? 'blur' : 'change';
                        if (item === 'required') {
                            rules[x].push({
                                required: true,
                                message: this.english ? ((field.title_en || field.title) + " is required!") : ('请输入' + field.title),
                                trigger: trigger
                            });
                        }
                        if (item.indexOf('min:') === 0 || item.indexOf('max:') === 0) {
                            let cmd = item.split(':');
                            if (cmd.length > 1) {
                                let v = {
                                    message: (cmd[0] === 'min' ? ('最小') : ('最大')) + cmd[1] + ('个字'),
                                    trigger: 'blur'
                                };
                                v[cmd[0]] = parseInt(cmd[1]);
                                rules[x].push(v);
                            }
                        }
                        if (item.indexOf('email') === 0) {
                            rules[x].push({
                                type: 'email',
                                message: ('请输入正确的邮箱格式'),
                                trigger: trigger
                            });
                        }
                    });
                }
            }
            return rules;
        },
        editing() {
            let check = this.plans.filter(i => i.edit);
            return check.length > 0;
        }
    },
    methods: {
        loadLesson(id) {
            let vm = this;
            this.$api('Admin.Course.Get', {id: id}, true).then(response => {
                vm.single = Object.assign({teacher_bio: ''}, response.data.data.data || {});
                vm.fields = response.data.data.fields;
                if (vm.single.id) {
                    this.loadPlanList();
                    this.loadRecordingsList();
                    this.loadCouldRecordList();
                    this.loadPauseDates();
                }
                vm.edit_mode = true;
                Object.keys(vm.fields).forEach(function (key) {
                    let x = vm.fields[key];
                    if (x.type === 'picture' && !vm.single[key]) {
                        vm.single[key] = [];
                    }
                });
            });
        },
        editLesson() {
            if (this.model) {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        this.$api('Admin.Course.Edit', this.single, true).then((res) => {
                                this.$notice.success('记录更新成功!');
                                if (parseInt(this.$route.params.id) === 0) {
                                    this.$router.replace("/admin/lesson/edit/" + res.data.data.id);
                                } else {
                                    this.$router.back();
                                }
                            }
                        );
                    } else {
                        this.$notice.error('请按照要求填写数据!');
                    }
                });
            }
        },
        rebuildLesson() {
            if (this.model) {
                this.$affirm('是否要重启课程?').then(() => {
                    this.$refs['editForm'].validate(valid => {
                        if (valid) {
                            const data = Object.assign(this.single, {"rebuild": 'YES'});
                            this.$api('Admin.Course.Edit', data, true).then((res) => {
                                    this.$notice.success('记录更新成功!');
                                    if (parseInt(this.$route.params.id) === 0) {
                                        this.$router.replace("/admin/lesson/edit/" + res.data.data.id);
                                    } else {
                                        this.loadLesson(this.$route.params.id);
                                        this.loadSemesterList();
                                    }
                                }
                            );
                        } else {
                            this.$notice.error('请按照要求填写数据!');
                        }
                    });
                });
            }
        },
        loadTeacherBio() {
            if (this.single.teacher_id) {
                this.$api('Admin.Course.TeacherBio', {'id': this.single.teacher_id}, true).then((res) => {
                    this.single.teacher_bio = res.data.data;
                });
            }
        },
        loadRecordingsList() {
            this.$api('Admin.Course.RecordingList', {'id': this.single.id}, true).then((res) => {
                this.recordings = res.data.data;
            });
        },
        loadCouldRecordList() {
            this.$api('Admin.Course.CloudRecordingList', {'id': this.single.id}, true).then((res) => {
                this.cloud_records = res.data.data;
            });
        },
        loadPlanList() {
            this.$api('Admin.Course.PlanList', {'id': this.single.id}, true).then((res) => {
                this.plans = [];
                let plans = res.data.data;
                plans.forEach(i => {
                    i.date = [i.date_from, i.date_to];
                    i.weekdays = i.week_day.split(',');
                    i.edit = false;
                    i.id = 0;
                    i.odd = "" + i.odd;
                    i.time = i.course_time.split('-');
                    this.plans.push(Object.assign({}, i));
                });
                this.addBlankPlan();
                this.makeSchedule();
            });
        },
        addBlankPlan() {
            let blank = this.plans.filter((i) => i.id === -1);
            if (blank.length === 0) {
                this.plans.push({
                    id: -1,
                    odd: '0',
                    date: [],
                    weekdays: [],
                    course_time: '08:00-22:00',
                    week_day: '',
                    date_from: '',
                    date_to: '',
                    edit: false,
                    time: ['08:00', '22:00'],
                })
            }
        },
        planReload() {
            this.loadPlanList();
            this.loadPauseDates();
        },
        planUpload() {
            this.$api('Admin.Course.PlanUpdate', {'id': this.single.id, 'plans': this.plans}, true).then(() => {
                this.$notice.success('排课更新完毕!');
            });
        },
        planEdit(index, row) {
            this.old_plan = Object.assign({}, row);
            row.edit = true;
        },
        planCancel(index, row) {
            this.plans[index] = Object.assign({}, this.old_plan);
            row.edit = false;
        },
        planSave(index, row) {
            if (row.date.length === 2 && this.$is_date(row.date[0]) && this.$is_date(row.date[1])) {
                row.date_from = row.date[0];
                row.date_to = row.date[1];
                if (row.weekdays.length > 0 || row.date_from === row.date_to) {
                    if (row.date_from === row.date_to) {
                        let d = this.$dateParse(row.date_from);
                        row.weekdays = ["日一二三四五六".substr(d.getDay(), 1)];
                    }
                    row.week_day = row.weekdays.join(",");
                    row.course_time = row.time.join("-");
                    row.course_time = row.course_time.replace(/：/ug, ":");
                    row.course_time = row.course_time.replace(/-/ug, "-");
                    let time_reg = /^(20|21|22|23|[0-1]\d):([0-5]\d)-(20|21|22|23|[0-1]\d):([0-5]\d)$/;
                    let result = row.course_time.match(time_reg);
                    if (result) {
                        let time_start = result[1] + result[2] / 60;
                        let time_end = result[3] + result[4] / 60;
                        if (time_end <= time_start) result = null;
                    }
                    if (result) {
                        if (row.odd >= 0 && row.odd <= 2) {
                            if (row.id === -1) row.id = 0;
                            row.edit = false;
                            this.addBlankPlan();
                            this.makeSchedule();
                        } else {
                            this.$notice.error('请正确选择循环周期!');
                        }
                    } else {
                        this.$notice.error('请正确输入时间,结束时间必须大于起始时间!');
                    }
                } else {
                    this.$notice.error('请正确选择星期!');
                }
            } else {
                this.$notice.error('开始结束的格式不对!');
            }
        },
        makeSchedule() {
            this.schedule.splice(0, this.schedule.length);
            this.plans.forEach(i => {
                if (i.id !== -1) {
                    let date_start = this.$dateParse(i.date_from);
                    let date_end = this.$dateParse(i.date_to);

                    let date_start_week_day = 0; //每逢周日就间隔
                    let step = parseInt(i.odd) * 7 + 1;
                    while (date_start <= date_end) {
                        let weekday = "日一二三四五六".substr(date_start.getDay(), 1)
                        if (i.weekdays.indexOf(weekday) !== -1) {
                            this.schedule.push({
                                date: date_start.Format("yyyy-MM-dd"), course_time: i.course_time, weekday: weekday,
                                reference: i.date_from + ' - ' + i.date_to + " [" + i.week_day + '] ' + i.course_time + " | " + ['每周循环', '隔周循环', '3周循环'][i.odd]
                            });
                        }
                        if (date_start.getDay() === date_start_week_day) {
                            date_start.setDate(date_start.getDate() + step);
                        } else {
                            date_start.setDate(date_start.getDate() + 1);
                        }
                    }
                }
            });
        },
        planDelete(index, row) {
            if (row.id === -1) return;
            this.$affirm('确定要删除此条排课吗?').then(() => {
                let found = -1;
                for (let i = 0; i < this.plans.length; i++) {
                    if (row.id === this.plans[i].id) {
                        found = i;
                    }
                }
                if (found !== -1) {
                    this.plans.splice(found, 1);
                }
                this.makeSchedule();
            })
        },
        loadPauseDates() {
            this.$api('Admin.Course.PauseTimesGet', {id: this.single.id}, 1).then(res => {
                this.pause_dates = res.data.data;
            });
        },
        handlePauseDateSwitch(date) {
            let found = false;
            for (let i = 0; i < this.pause_dates.length; i++) {
                if (this.pause_dates[i] === date) {
                    this.pause_dates.splice(i, 1);
                    found = true;
                }
            }
            if (!found) this.pause_dates.push(date);
            this.$api('Admin.Course.PauseTimesUpdate', {id: this.single.id, dates: this.pause_dates}, 1).then(() => {
                this.$notify.success('暂停日期已保存.');
            });
        },
        handleTabClick(tab) {
            if (tab.label === "报表下载") {
                this.searchMeetings();
            }
        },
        addAcademy() {
            this.$prompt('请输入单位名称', '输入', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({value}) => {
                if (value) {
                    this.$api('Admin.Course.AddAcademy', {'academy': value}, true).then((res) => {
                        const data = res.data.data;
                        console.log(data, this.fields.academy_id);
                        if (!data.exists) {
                            this.$set(this.fields.academy_id.options, data.id, data.name);
                        }
                        this.$set(this.single, 'academy_id', data.id + '');
                        this.$notice.success('增加完毕!');
                    });
                }
            });
        },
        downloadReport() {
            this.$api('User.Statistics.Statistics', {id: this.uuid}, 1).then(res => {
                this.table = res.data.data;
                this.$nextTick(function () {
                    this.exportExcel();
                })
            });
        },
        searchMeetings() {
            this.$api('User.Statistics.meetings', {id: this.single.id}, 1).then(res => {
                this.meetings = res.data.data;
            });
        },
        // 导出表格
        exportExcel() {
            /* generate workbook object from table */
            let wb = XLSX.utils.table_to_book(this.$refs['excel_table'], {raw: true});
            /* get binary string as output */
            let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
            let now = new Date().Format('yyyy-MM-dd');
            try {
                FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `会议使用表-${now}.xlsx`)
            } catch (e) {
                console.log(e, wbout)
            }
            return wbout
        },

        //*** recording functions ***//
        recordingEdit(index, row) {
            this.$api('Admin.Course.RecordingGet', row, true).then((res) => {
                const data = res.data.data;
                this.record = Object.assign({}, data);
                this.dialog_recording_add = true;
            });
        },
        recordingDelete(index, row) {
            this.$affirm('确定要删除此录像吗?').then(() => {
                this.$api('Admin.Course.RecordingDelete', row, true).then(() => {
                    this.loadRecordingsList();
                    this.$notice.success('删除录像成功!');
                });
            })
        },
        recordingAdd() {
            this.record = {course_id: this.single.id, date: null, memo: null, url: null, id: 0};
            this.dialog_recording_add = true;
        },
        recordingReload() {
            this.loadRecordingsList();
        },
        //*** upload functions ***/
        handleRecordRemoveVideo() {
            this.$affirm('您需要删除录像后重传吗?').then(
                () => {
                    this.record.video = null;
                }
            )
        },
        handleRecordingSuccess(res) {
            this.$set(this.record, 'video', res._data);
            if (this.$is_datetime(this.record.date)) {
                this.doRecordingUpload();
            }
        },
        handleRecordingUploadError(obj) {
            console.log(obj);
            this.$notice.error(obj._message)
        },
        doRecordingEditCancel() {
            if (this.$refs.recording_uploader) this.$refs.recording_uploader.clearFiles();
            this.dialog_recording_add = false;
        },
        doRecordingUpload() {
            //check fields are OK
            if (this.$is_datetime(this.record.date)) {
                if (this.$refs.recording_uploader && this.$refs.recording_uploader.getStatus() === 'BUSY') {
                    this.$notice.error('请等待文件上传完成!');
                } else {
                    if (!this.record.video && !this.record.url) { // 已经有video的信息了
                        this.$notice.error('请上传录像，或者提供录像连接!');
                    } else {
                        this.$api('Admin.Course.RecordingUpload', this.record, true).then(() => {
                            this.loadRecordingsList();
                            this.dialog_recording_add = false;
                            this.$notice.success('记录更新成功!');
                        });
                    }
                }
            } else {
                this.$notice.error('请正确填写日期和时间');
            }
        },
        cloudRecordingEdit(index, row) {
            if (row) {
                this.cloud_record = Object.assign({record_date: '', course_id: this.single.id}, row);
                let record_date = "";
                this.schedule.forEach((x) => {
                    if (x.date === this.cloud_record.record_time.substr(0, 10)) {
                        record_date = x.date + ' ' + x.course_time.substr(0, 5);
                    }
                });
                this.cloud_record.record_date = record_date;
                this.cloud_record_dialog = true;
            }
        },
        doCloudRecordingUpload() {
            if (!this.$is_datetime(this.cloud_record.record_date)) {
                this.$notice.error('请选择录像时间');
            } else {
                this.$api("Admin.Course.CloudRecordingTransfer", this.cloud_record, true).then(() => {
                    this.recordingReload();
                    this.cloud_record_dialog = false;
                })
            }
        },
        redoUpload() {
            if (this.cloud_record) {
                this.$api('Admin.Course.CloudRecordingRedo', this.cloud_record, true).then(() => {
                    this.cloud_record_dialog = false;
                    this.$notify.success('云端录像已经开始重新下载，请等待完成.');
                    const vm =this;
                    setTimeout(() => {
                        vm.loadCouldRecordList();
                    },1000)
                });
            }
        }
    },
    mounted() {
        let id = this.$route.params.id;
        this.loadLesson(id);
        this.loadSemesterList();
    }
}
