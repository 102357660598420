<template>
    <div class="el-input" :class="'el-input--'+size">
        <div class="el-input d-flex">
            <el-upload
                    class="uploader"
                    :action="api_upload_file"
                    :show-file-list="false"
                    :on-success="handleFileSuccess"
                    :before-upload="beforeFileUpload"
                    :on-error="handleUploadError"
                    :data="within"
                    :headers="headers"
                    :on-progress="handleProgress"
                    :accept="extensions"
            >
                <slot></slot>
            </el-upload>
        </div>
    </div>
</template>

<script>
    import api from "../config/api.js"
    import {mapMutations,mapState} from "vuex"

    export default {
        name: "common-file-upload",
        components: {},
        data() {
            return {
                api_upload_file: this.uploadUrl,
                is_loading: false,
                headers: null,
                token: null,
                support_error:'',
            }
        },
        props: {
            size: {
                type: String,
                default() {
                    return 'medium'
                },
            },
            extensions:{
                type:Array,
                default(){
                    return ['xls','xlsx'];
                }
            },
            fileSize:{
                type:Number,
                default(){
                    return 10;
                }
            },
            uploadUrl:{
                type:String,
                default(){
                    return api.url + api.modules.User.UploadFile;
                }
            },
            silent:{ type:Boolean,default(){
                    return false;
                }},
            extra:{ type : Object,default(){
                return {};
                }}
        },
        computed: {
            ...mapState(['user','process']),
            within(){
                return Object.assign({user_token:this.token,type:'attachment'},this.extra);
            }
        },
        created() {
            this.token = this.user.token;

        },
        methods: {
            ...mapMutations('process',['start','stop']),
            handleFileSuccess(res) {
                this.stop();
                const vm = this;
                vm.$emit('success',res);
                if (!vm.silent) {
                    if (res.success) { // success
                        vm.$notice.success('上传成功!');
                        vm.$emit('reload');
                    } else {
                        vm.$notice.error(res.text);
                    }
                }
            },
            handleUploadError(error) {
                this.stop();
                console.error('err:',error);
                this.$messageBox.error('错误！导入文件发生了错误!');
            },
            beforeFileUpload(file) {
                const sizeCheck = file.size / 1024 / 1024 <= this.fileSize;
                const split = file.name.split('.');
                const ext = split[split.length - 1].toLocaleLowerCase();

                const extensions = this.extensions.map((item)=>{
                    const a = item.split('/');
                    return (a.length === 1)?  a[0]:a[1];
                });
                const extCheck = (-1 !== extensions.indexOf(ext));

                if (!extCheck) {
                    this.$notice.error(this.support_error);
                } else {
                    if (!sizeCheck) {
                        this.$notice.error(this.support_error);
                    }
                }
                const good = extCheck && sizeCheck;
                if (good) {
                    this.start();
                    this.$emit('start');
                }
                return good;
            },
            handleProgress(event){
                this.$emit('progress',event.loaded/event.total*100);
            }
        },
        mounted() {
            const message = '上传仅仅支持%s,%s';
            this.support_error = message.arrayReplace([this.extensions.join(','),this.fileSize+'M']);
        },

    }
</script>

<style scoped lang="scss">

</style>
