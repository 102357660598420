<template>
    <el-row :gutter="20" v-loading="process.loading">
        <el-form :model="single" label-width="100px" ref="editForm" :rules="lessonRules">
            <el-tabs type="" v-model="tab" tab-position="top" style="padding-left: 20px;padding-right: 20px;">
                <el-tab-pane label="基本信息">
                    <el-row style="padding-right: 20px;" v-if="fields.street">
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.street" field="street">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.academy" field="academy">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.course_number"
                                                      field="course_number">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.course_name"
                                                      field="course_name">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.brief" field="brief">
                            </front-single-form-editor>
                        </el-col>

                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.teacher_id" field="teacher_id">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="12">
                            <front-single-form-editor :single="single" :item="fields.assistant_id"
                                                      field="assistant_id">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <el-row type="flex">
                                <el-col>
                                    <front-single-form-editor :single="single" :item="fields.teacher_bio"
                                                              style="margin-bottom: 0"
                                                              field="teacher_bio">
                                    </front-single-form-editor>
                                </el-col>
                                <el-button type="info" plain size="small" style="margin-left: 10px;"
                                           @click="loadTeacherBio">载入简介
                                </el-button>
                            </el-row>
                        </el-col>
                    </el-row>
                    <el-divider></el-divider>
                    <el-row style="padding-left: 20px">
                        <el-button size="medium" type="primary" icon="el-icon-plus" @click="editLesson">保存课程</el-button>
                        <el-button size="medium" type="default" icon="el-icon-close" @click="$router.back()">放弃保存
                        </el-button>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="排课设置" :disabled="!single.id">
                    <el-card class="mb-3" :body-style="{ padding: '0px' }">
                        <el-table
                            :data="plans"
                            size="medium"
                            :border="true"
                            style="width: 100%">
                            <el-table-column
                                label="开始结束日期">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-date-picker
                                            v-model="scope.row['date']"
                                            type="daterange"
                                            :range-separator="('至')"
                                            size="small"
                                            :start-placeholder="('选择开始日期')"
                                            value-format="yyyy-MM-dd"
                                            :end-placeholder="('选择结束日期')">
                                        </el-date-picker>
                                    </div>
                                    <div v-else>
                                        <el-button type="text" icon="el-icon-plus" v-if="scope.row.id === -1"
                                                   @click="planEdit(scope.$index, scope.row)">添加排课计划
                                        </el-button>
                                        <span v-else>{{ scope.row.date_from }}~{{ scope.row.date_to }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="星期">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-select
                                            clearable filterable
                                            v-model="scope.row['weekdays']"
                                            placeholder="选择星期,日期相同则留空"
                                            multiple
                                            :style="{width:'100%'}">
                                            <el-option v-for="(option) in '一二三四五六日'"
                                                       :label="option" :value="option+''" :key="option"></el-option>
                                        </el-select>
                                    </div>
                                    <div v-else>
                                        {{ scope.row.week_day }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="时间" width="180">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-input
                                            clearable
                                            v-model="scope.row['course_time']"
                                            placeholder="08:00-09:00"
                                            :style="{width:'100%'}">
                                        </el-input>
                                    </div>
                                    <div v-else>
                                        {{ scope.row.course_time }}
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="循环设置">
                                <template slot-scope="scope" >
                                    <div v-if="scope.row.edit">
                                    <el-select
                                        clearable filterable
                                        v-model="scope.row['odd']"
                                        placeholder="选择循环"
                                        :style="{width:'100%'}">
                                        <el-option v-for="(option,key) in ['每周循环','隔周循环','3周循环']"
                                                   :label="option" :value="key+''" :key="key"></el-option>
                                    </el-select>
                                    </div>
                                    <div v-else>
                                        {{['每周循环', '隔周循环', '3周循环'][scope.row.odd]}}
                                    </div>
                                </template>

                            </el-table-column>
                            <el-table-column label="操作" width="120">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.edit">
                                        <el-button
                                            size="small" circle icon="el-icon-check" type="default"
                                            @click="planSave(scope.$index, scope.row)"></el-button>
                                        <el-button
                                            size="small" circle icon="el-icon-close" type="danger"
                                            @click="planCancel(scope.$index, scope.row)"></el-button>
                                    </div>
                                    <div v-else>
                                        <el-button
                                            v-if="scope.row.id >=0"
                                            size="small" circle icon="el-icon-edit" type="default"
                                            @click="planEdit(scope.$index, scope.row)"></el-button>
                                        <el-button
                                            v-if="scope.row.id >=0"
                                            size="small" circle icon="el-icon-delete" type="danger"
                                            @click="planDelete(scope.$index, scope.row)"></el-button>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="p-3">
                        <el-button type="primary" icon="el-icon-check" @click="planUpload" :disabled="editing" :loading="process.loading">保存排课</el-button>
                            <el-button type="default" icon="el-icon-refresh" @click="planReload" :disabled="editing">重新载入</el-button>
                        </div>                    </el-card>
                    <el-divider class="text-muted">排课详细时间表</el-divider>
                    <el-table
                        :data="schedule"
                        size="mini"
                        style="width: 100%;">
                        <el-table-column prop="date" label="日期" width="120"></el-table-column>
                        <el-table-column prop="course_time" label="时间" width="120"></el-table-column>
                        <el-table-column prop="weekday" label="周" width="120"></el-table-column>
                        <el-table-column prop="reference" label="排课计划"></el-table-column>
                    </el-table>

                </el-tab-pane>
                <el-tab-pane label="会议信息" :disabled="!single.id">
                    <el-row>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.tm_meeting_code"
                                                      field="tm_meeting_code">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.tm_start_url"
                                                      field="tm_start_url">
                            </front-single-form-editor>
                        </el-col>
                        <el-col :span="24">
                            <front-single-form-editor :single="single" :item="fields.streaming" field="streaming">
                            </front-single-form-editor>
                        </el-col>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="报表下载" :disabled="!single.id">
                </el-tab-pane>
            </el-tabs>
        </el-form>

    </el-row>
</template>

<script>

import {mapState} from "vuex";
import FrontSingleFormEditor from "@/components/front-single-form-editor";
import FrontMixins from "@/common/mixins/front-mixins";
import Echo from "@/common/mixins/echo-mixin";

export default {
    name: "profile",
    components: {FrontSingleFormEditor},
    mixins: [FrontMixins, Echo],
    data() {
        return {
            single: {},
            fields: {},
            plans: [],
            schedule: [],
            edit_mode: true,
            dual: true,
            tab: 0,
            old_plan: {},
        }
    },
    created() {
        this.model = 'Admin.Plan';
    },
    watch: {
        $route() {
            let id = this.$route.params.id;
            this.loadLesson(id);
        },
    },
    computed: {
        ...mapState(['user', 'process', 'preset']),

        lessonRules() {
            let rules = {};
            for (let x in this.fields) {
                const field = this.fields[x];
                if (field.validation) {
                    rules[x] = [];
                    field.validation.forEach(item => {
                        const trigger = ['text', 'textarea', 'number'].includes(item.type) ? 'blur' : 'change';
                        if (item === 'required') {
                            rules[x].push({
                                required: true,
                                message: this.english ? ((field.title_en || field.title) + " is required!") : ('请输入' + field.title),
                                trigger: trigger
                            });
                        }
                        if (item.indexOf('min:') === 0 || item.indexOf('max:') === 0) {
                            let cmd = item.split(':');
                            if (cmd.length > 1) {
                                let v = {
                                    message: (cmd[0] === 'min' ? ('最小') : ('最大')) + cmd[1] + ('个字'),
                                    trigger: 'blur'
                                };
                                v[cmd[0]] = parseInt(cmd[1]);
                                rules[x].push(v);
                            }
                        }
                        if (item.indexOf('email') === 0) {
                            rules[x].push({
                                type: 'email',
                                message: ('请输入正确的邮箱格式'),
                                trigger: trigger
                            });
                        }
                    });
                }
            }
            return rules;
        },
        editing(){
            let check = this.plans.filter(i=>i.edit);
            return check.length > 0;
        }
    },
    methods: {
        loadLesson(id) {
            let vm = this;
            this.$api('Admin.Course.Get', {id: id}, true).then(response => {
                vm.single = Object.assign({teacher_bio:''},response.data.data.data || {});
                vm.fields = response.data.data.fields;
                if (vm.single.id) {
                    this.loadPlanList()
                }
                vm.edit_mode = true;
                Object.keys(vm.fields).forEach(function (key) {
                    let x = vm.fields[key];
                    if (x.type === 'picture' && !vm.single[key]) {
                        vm.single[key] = [];
                    }
                });
            });
        },
        editLesson() {
            if (this.model) {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        this.$api('Admin.Course.Edit', this.single, true).then((res) => {
                                this.$notice.success('记录更新成功!');
                                console.log(res);
                                if (parseInt(this.$route.params.id) === 0) {
                                    this.$router.replace("/admin/lesson/edit/" + res.data.data.id);
                                } else {
                                    this.$router.back();
                                }
                            }
                        );
                    } else {
                        this.$notice.error('请按照要求填写数据!');
                    }
                });
            }
        },
        loadTeacherBio() {
            if (this.single.teacher_id) {
                this.$api('Admin.Course.TeacherBio', {'id': this.single.teacher_id}, true).then((res) => {
                    this.single.teacher_bio = res.data.data;
                });
            }
        },
        loadPlanList() {
            this.$api('Admin.Course.PlanList', {'id': this.single.id}, true).then((res) => {
                this.plans = [];
                let plans = res.data.data;
                plans.forEach(i => {
                    i.date = [i.date_from, i.date_to];
                    i.weekdays = i.week_day.split(',');
                    i.edit = false;
                    i.id = 0;
                    i.odd = ""+i.odd;
                    this.plans.push(Object.assign({},i));
                });
                this.addBlankPlan();
                this.makeSchedule();
            });
        },
        addBlankPlan() {
            let blank = this.plans.filter((i) => i.id === -1);
            if (blank.length === 0) {
                this.plans.push({
                    id: -1,
                    odd: '0',
                    date: [],
                    weekdays: [],
                    course_time: '',
                    week_day: '',
                    date_from: '',
                    date_to: '',
                    edit: false
                })
            }
        },
        planReload(){
            this.loadPlanList();
        },
        planUpload(){
            this.$api('Admin.Course.PlanUpdate', {'id': this.single.id,'plans':this.plans}, true).then(() => {
                this.$notice.success('排课更新完毕!');
            });
        },
        planEdit(index, row) {
            this.old_plan = Object.assign({}, row);
            row.edit = true;

        },
        planCancel(index, row) {
            this.plans[index] = this.old_plan;
            row.edit = false;
        },
        planSave(index, row) {
            if (row.date.length === 2 && this.$is_date(row.date[0]) && this.$is_date(row.date[1])) {
                row.date_from = row.date[0];
                row.date_to = row.date[1];
                if (row.weekdays.length > 0 || row.date_from === row.date_to) {
                    if (row.date_from === row.date_to) {
                        let d = this.$dateParse(row.date_from);
                        row.weekdays = ["日一二三四五六".substr(d.getDay(), 1)];
                    }
                    row.week_day = row.weekdays.join(",");
                    row.course_time = row.course_time.replace(/：/ug,":");
                    row.course_time = row.course_time.replace(/-/ug,"-");
                    let time_reg = /^(20|21|22|23|[0-1]\d):([0-5]\d)-(20|21|22|23|[0-1]\d):([0-5]\d)$/;
                    let result = row.course_time.match(time_reg);
                    if (result){
                        let time_start = result[1]+result[2]/60;
                        let time_end = result[3]+result[4]/60;
                        if (time_end<=time_start) result = null;
                    }
                    if (result) {
                        if (row.odd >= 0 && row.odd <= 2) {
                            if (row.id === -1) row.id = 0;
                            row.edit = false;
                            this.addBlankPlan();
                            this.makeSchedule();
                        } else {
                            this.$notice.error('请正确选择循环周期!');
                        }
                    } else {
                        this.$notice.error('请正确输入时间,结束时间必须大于起始时间!');
                    }
                } else {
                    this.$notice.error('请正确选择星期!');
                }
            } else {
                this.$notice.error('开始结束的格式不对!');
            }
        },
        makeSchedule() {
            this.schedule.splice(0,this.schedule.length);
            this.plans.forEach(i=>{
                if (i.id !== -1) {
                    let date_start = this.$dateParse(i.date_from);
                    let date_end = this.$dateParse(i.date_to);

                    let date_start_week_day = 0; //每逢周日就间隔
                    let step = parseInt(i.odd) * 7 + 1;
                    while (date_start <= date_end) {
                        let weekday = "日一二三四五六".substr(date_start.getDay(), 1)
                        if (i.weekdays.indexOf(weekday) !== -1) {
                            this.schedule.push({
                                date: date_start.Format("yyyy-MM-dd"), course_time: i.course_time, weekday: weekday,
                                reference: i.date_from + ' - ' + i.date_to + " [" + i.week_day + '] ' + i.course_time + " | " + ['每周循环', '隔周循环', '3周循环'][i.odd]
                            });
                        }
                        if (date_start.getDay() === date_start_week_day) {
                            date_start.setDate(date_start.getDate() + step);
                        } else {
                            date_start.setDate(date_start.getDate() + 1);
                        }
                    }
                }
            });
        },
        planDelete(index, row) {
            if (row.id === -1) return;
            this.plans.splice(index, 1);
            this.makeSchedule();
        }
    }
    ,
    mounted() {
        let id = this.$route.params.id;
        this.loadLesson(id);
    }
}
</script>

<style scoped lang="scss">

</style>
