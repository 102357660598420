import {mapState} from "vuex";

export default {
    data() {
        return {
            error: false,
            status: {},
            timer: 0,
            is_upload: false,
            empty_confirm:false
        }
    },
    computed: {
        modelImportUrl() {
            return this.$apiUrl(this.model+'.Import');
        },
        ...mapState(['user','preset','process'])
    },
    methods: {
        handleEmpty() {
            this.$api( this.model+'.Empty', {}, 1).then(() => {
                this.$notice.success('数据已清空!');
            });
        },
        handleStop() {
            this.$api(this.model +'.Stop', {channel:this.channel}, 1).then(() => {
                this.$notice.success('已经提交中断要求，请等待!');
                this.getStatus();
            });
        },
        getStatus(res) {
            if (res) {
                this.status = Object.assign({},this.status,res.data.data);
            } else {
                if (!this.channel) this.makeChannel();
                this.$api(this.model + '.Status', {channel: this.channel}, 1).then(res => {
                    this.status = Object.assign({}, this.status, res.data.data);
                });
            }
        },
        handleUploadSuccess(res) {
            if (res.success) {
                this.$notice.success('上传完成,请等待导入完成!');
                this.status = Object.assign({},this.status,res.data.data);
            }
            else {
                this.$notice.error('上传失败!' + (res.data?res.data:res.text));
            }
            this.getStatus();
        },
        handleUploadStart() {
            this.is_loading = true;
            this.events =  {
                status:{},
                messages:[],
            };
            this.getStatus();
        },
        handlePusherData(data){
            if (data && data.message && data.message.data ) this.status = Object.assign({},this.status,data.message.data);
        },

    },
    mounted(){
        this.getStatus();
        this.initPusher(this.handlePusherData);
    },
    beforeRouteLeave(to, from, next) {
        this.disconnectPusher();
        next();
    },

}
