<template>
    <div>
        <div class="vue-slice-uploader">
            <section>
                <i class="el-icon-upload"></i>
                <div>请上传mp4格式的文件，<em>点击选择</em></div>
                <input type="file"
                       ref="file"
                       :accept="suffix"
                       :multiple="multiple"
                       @change="handleFileChange">
            </section>
            <div class="text-info" style="margin-top: 0;">
                <table style="width:100%">
                    <tbody>
                    <tr v-for="x in allFiles" :key="x._index">
                        <td style="width: 100px;"><div style="width: 100px;white-space:nowrap;text-overflow:ellipsis;overflow: hidden"> {{ x.name }} </div></td>
                        <td style="width: 220px">
                            <div v-if="x._status">
                                <em class="text-danger" v-if="x._status==='warning'">上传失败</em>
                                <em class="text-danger" v-else-if="x._status==='cancel'">用户取消</em>
                                <em class="text-success" v-else >上传成功</em>
                            </div>
                            <div v-else>
                                <el-progress :percentage="x._pct" :stroke-width="24" text-inside></el-progress>
                            </div>
                        </td>
                        <td style="width: 20px;text-align: right">
                            <el-button type="danger" circle icon="el-icon-close"
                                       size="mini" @click="handleCancelUpload(x)"
                                       style="width: 18px;height: 18px;padding: 2px;margin-left: 3px;">

                            </el-button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FileUploader from './file-slice-uploader'
import {mapState} from "vuex"

export default {
    name: 'slice-uploader',
    data() {
        return {
            allFiles: [],
            state: 0,
        }
    },
    props: {
        chunkSize: {
            type: Number,
            default: Math.pow(1024, 2)
        },
        formData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        auto: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        showButton: {
            type: Boolean,
            default: true
        },
        suffix: {
            type: String,
            default: 'video/mp4'
        },
        action: {
            type: String,
            default: ''
        },
        unique: {
            type: Boolean,
            default: false
        }
    },
    created() {
        this.suffixList = this.suffix.split(',')
    },
    computed: {
        ...mapState(['user']),
    },
    methods: {
        handleFileChange(e) {
            let files = e.target.files, addFileList = [];
            if (!this.multiple) {
                if (files.length) {
                    let file = files[0];
                    file._index = 0;
                    file._pct = 0;
                    file._code = 0;
                    addFileList.push(file);
                    if (this.allFiles.length) {
                        if (this.allFiles[0]._uploader)
                            this.allFiles[0]._uploader.cancel();
                    }
                    this.$emit('change', file, this.allFiles)
                    this.allFiles = [file];
                } else {
                    this.allFiles = [];
                }
            } else {
                for (let i = 0; i < files.length; i++) {
                    let file = files[i]
                    this.allFiles.push(file)
                    file._index = this.allFiles.length - 1;
                    addFileList.push(file)
                }
                this.$emit('change', this.allFiles)
            }
            if (this.auto) {
                this.upload(addFileList)
            }
            this.$refs["file"].value = null;
        },
        handleCancelUpload(file) {
            if (file._uploader) {
                if (!file._status) {
                    file._uploader.cancel();
                    file._status = 'cancel';
                    return;
                }
            }
            this.allFiles.splice(file._index,1);
        },
        upload(fileList) {
            if (!this.action) {
                console.warn('找不到上传路径')
                return;
            }
            fileList.map((file) => {
                if (!file._uploader) {
                    let uploader = new FileUploader(file, {
                        url: this.action,
                        unique: this.unique,
                        chunkSize: this.chunkSize,
                        formData: this.formData,
                        headers: {"Accept": 'application/json', "user-token": this.user.token},
                        progress: (code, percent, msg) => {
                            file._code = code;
                            file._msg = msg;
                            file._pct = percent;
                            this.$emit('on-progress', file, {
                                code, percent, msg
                            })
                            this.$set(this.allFiles, file._index, file);
                        },
                        getSpeed: (speed) => {
                            file._speed = speed;
                            this.$emit('on-speed', file, speed);
                        },
                        complete: (data) => {
                            file._status = 'success';
                            const ret = JSON.parse(data.response);
                            file._data = ret.data.video;
                            this.$set(this.allFiles, file._index, file);
                            this.$emit('on-complete', file);
                        },
                        failed: (error) => {
                            file._status = 'warning';
                            if (error.status === 415) {
                                const res = JSON.parse(error.response);
                                file._message = res.text;
                            } else if (error.status === 401) {
                                file._message = '您需要重新登录才能上传!';
                            } else {
                                file._message = '网络错误!';
                            }
                            this.$set(this.allFiles, file._index, file);
                            this.$emit('on-failed', file);
                        }
                    })
                    file._uploader = uploader;
                    file._pct = 0;
                    uploader.run();
                }
            })
        },
        start() {
            this.upload(this.allFiles);
        },
        clearFiles() {
            if (this.allFiles.length) {
                if (this.allFiles[0]._uploader)
                    this.allFiles[0]._uploader.cancel();
            }
            this.allFiles = [];
        },
        getStatus(){
            let ret = 'IDLE';
            this.allFiles.forEach((i)=>{
                if (!i._status) ret="BUSY";
            });
            return ret;
        }
    }
}
</script>

<style lang="scss">
.vue-slice-uploader {
    position: relative;
    height: 200px;
    width: 360px;

    & > section {
        background-color: #fff;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        box-sizing: border-box;
        width: 360px;
        height: 170px;
        text-align: center;
        cursor: pointer;
        position: relative;
        transition: all ease-in 200ms;

        &:hover {
            border: 1px dashed #0d8ddc;

            & > i {
                color: #0d8ddc;
            }
        }

        & > i {
            font-size: 67px;
            color: #C0C4CC;
            margin: 40px 0 16px;
            line-height: 50px;
            transition: all ease-in 200ms;
        }

        & > div {
            color: #606266;
            font-size: 14px;
            text-align: center;

            & > em {
                color: #0d8ddc;
            }
        }


        input[type='file'] {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            z-index: 2;
        }

    }


}
</style>
