<template>
    <div class="w-100 h-100 position-relative host"
         style="background-image:url('/images/login-bg.jpg');background-repeat: no-repeat;background-size:cover">
        <page-header></page-header>
        <div class="page-body" style="background-color: whitesmoke;">
            <div style="min-height:500px;background-color: whitesmoke;">
                <div style="padding: 10px;">
                    <el-row class="container ">
                        <div class="d-flex mt-5" style="width: 350px;margin: auto">
                            <div style="flex:0 0 1em;line-height: 40px;font-size: 24px;"> 1)</div>
                            <el-input v-model="meeting_id"
                                      style="font-size: 24px;flex:1 1 10em;text-align: center"
                                      size="large" placeholder="请输入会议号"></el-input>
                            <el-button :loading="process.loading" class="ml-2" size="large" type="primary" plain
                                       @click="searchMeetings">查询 会议清单
                            </el-button>
                        </div>
                    </el-row>
                    <div class="line mt-3 mb-3"></div>
                    <el-row class="container mb-3">
                        <div class="d-flex" style="width: 350px;margin: auto">
                            <div style="flex:0 0 1em;line-height: 40px; font-size: 24px;"> 2)</div>
                            <el-select ref="meetings" v-model="single" placeholder="请选择" style="flex:1 1 10em;" filterable no-data-text="未找到会议">
                                <el-option
                                        v-for="item in meetings"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                            <el-button :loading="process.loading" class="ml-2" size="large" type="primary" plain
                                       @click="downloadReport">查询 参会清单
                            </el-button>
                        </div>
                    </el-row>

                    <div class="line"></div>
                    <el-row>
                        <div :loading="process.loading" ref="excel_table">
                            <table >
                                <tbody>
                                <tr v-for="(x,i) in table" :key="i">
                                    <td v-for="(y,j) in x" :key="j" v-html="y"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="page-footer">
            <page-footer></page-footer>
        </div>
    </div>
</template>

<script>
    import PageHeader from "../components/page-header";
    import PageFooter from "../components/page-footer";
    import XLSX from "xlsx";
    import FileSaver from "file-saver";
    import {mapState} from 'vuex'

    export default {
        name: "host",
        components: {PageHeader, PageFooter},
        data() {
            return {
                meeting_id: '',
                table: {headers: [], data: []},
                meetings: [],
                single: null,
            }
        },
        computed: {
            ...mapState(['process'])
        },
        methods: {
            searchMeetings() {
                this.$api('User.Statistics.Meetings', {meeting_id: this.meeting_id}, 1).then(res => {
                    this.meetings = res.data.data;
                    this.$refs['meetings'].focus();
                });
            },
            downloadReport() {
                if(!this.single) return;
                this.$api('User.Statistics.Statistics', {id: this.single}, 1).then(res => {
                    this.table = res.data.data;
                    this.$nextTick(function () {
                        this.exportExcel();
                    })
                });
            },
            // 导出表格
            exportExcel() {
                /* generate workbook object from table */
                let wb = XLSX.utils.table_to_book(this.$refs['excel_table'],{raw:true});
                /* get binary string as output */
                let wbout = XLSX.write(wb, {bookType: 'xlsx', bookSST: true, type: 'array'});
                let now = new Date().Format('yyyy-MM-dd');
                try {
                    const header = this.meeting_id;
                    FileSaver.saveAs(new Blob([wbout], {type: 'application/octet-stream'}), `${header}-${now}.xlsx`)
                } catch (e) {
                    console.log(e, wbout)
                }
                return wbout
            },
        },
    }
</script>

<style scoped lang="scss">

    .host {
        .page-header {
            height: 120px;
            padding: 10px 0;

            .logo {
                display: flex;

                img {
                    max-height: 70px;
                }

            }

        }

    }


</style>
