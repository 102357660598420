<template>
    <div :class="{'is-required':required}" class="el-form-item" >
        <label class="el-form-item__label" :style="{width: labelWidth}">
            {{label}}:</label>
        <div class="el-form-item__content" :style="{marginLeft: labelWidth}">
            <slot></slot>
        </div>
    </div>
</template>
<script>
export default {
    name: 'fake-form-item',
    computed:{
      labelWidth(){
          return this.width+'px';
      }
    },
    props: {
        width: {
            type: Number,
            default() {
                return ''
            }
        },
        label: {
            type: String,
            default() {
                return ''
            }
        },
        required: {
            type: Boolean,
            default() {
                return false
            }
        },
    }
}
</script>
